.dashboard-panel {
  overflow: hidden;
  border-radius: 10px;

  @media all and (max-width: 1100px) {
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 25px;
  }
}
