.provider-managers-form {
  &__title {
    margin-top: 30px;
  }

  &__list {
    min-height: 350px;
    margin-top: 10px;

    &__email {
      margin: 0 10px;
    }

    &__status.ant-tag.ant-tag-red,
    .ant-tag.ant-tag-green {
      width: 95px;
      white-space: normal;
      text-align: center;
      margin-left: 10px;
    }

    &__remove__button {
      margin-top: 5px;
    }
  }

  .ant-list-item-meta-title {
    color: var(@heading-color);
  }

  .ant-list-item-action {
    margin-left: 0px;
    display: flex;
    align-items: center;
  }

  .ant-list-item-meta-content {
    width: 100px;
  }
}
