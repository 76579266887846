.signup {
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  background-color: @light-gray;
  overflow-y: scroll;

  &__inner {
    min-height: 100vh;
    padding: 30px 0;
    display: flex;
    align-items: center;

    @media all and (max-width: 1100px) {
      padding: 0;
    }
  }

  &__terms {
    &__links {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px;
    left: 0;
    overflow: hidden;
    z-index: 1;

    @media all and (max-width: 1100px) {
      display: none;
    }
  }

  &__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__img {
      width: 90px;
      height: auto;
    }
  }

  &__container {
    display: block;
    max-width: 100vw;
    width: 100%;

    &--responsive {
      @media all and (min-width: 1100px) and (max-height: 750px) {
        margin: calc(48px + 182px) 0 30px;
      }
    }

    @media all and (max-width: 1100px) {
      min-height: 100vh;
      padding-bottom: 60px;
      overflow-y: auto;
    }

    &__barButtons {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      margin-top: -15px;
    }

    &__register {

      &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 0;

        @media all and (max-width: 1100px) {
          padding: 0 !important;
        }

        &__helpButtons {
          background-color: black;
          border: 1px solid red;
          display: flex;
        }

        &__form {

          &__headerDescription {
            margin-bottom: 10px;
            color: black;
          }

          &__container {
            padding-top: 35px;
            width: 100%;
            display: block;

            &__title {
              color: black;
            }

            &__text {
              display: block;
            }
          }

          &__hiddenBar {
            margin-top: 10px;
            margin-left: 20px;
            max-height: 74px;

            &__text {
              font-size: 12px;
              margin: 0;
              padding: 0;
            }
          }

          &__footer {
            flex: 1;
            display: flex;
            width: 100%;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 20px;

            &__button,
            .advanced-button {
              width: 200px;
              box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
                0 9px 28px 8px rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
    }
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    color: black;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: black;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #ffec00;
  }
}
