.menu {
  background-color: black !important;
  height: 100%;
  overflow: auto;

  &__content {
    height: 100% !important;

    &__item {
      &__submenu {
        div.ant-menu-submenu-title {
          padding-left: 16px !important;
          align-items: center;
          justify-content: flex-start;
        }

        span {
          margin-left: 20px;
        }
      }

      &__icon {
      }

      &__text {
        margin-left: 5px;
      }
    }
  }
}

.ant-layout-sider-collapsed {
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      padding-left: 30px !important;

      & > span {
        display: none;
      }
    }
  }
}

@hack: true; @import "/Users/teomoreiras/Documents/Workspace/Tiffin/tiffin-foods-admin/src/styles/less/_custom-ant.less";