.header {
  position: fixed;
  z-index: 2;
  width: 100%;
  display: flex;
  padding: 0;
  height: 48px;
  justify-content: center;
  align-items: center;
  background-color: white;


  &__wrapper {

    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 0;
    border-radius: 0;
    height: 100%;
    flex: 1;
    box-shadow:
      0 1px 8px 0 rgb(0 0 0 / 12%);

    .ant-card-body {
      flex: 1;
      padding: 0;
    }
  }

  &__trigger__button {
    color: black !important;
    font-size: 20pt;
    margin-top: 2px;
    margin-left: 12px;
  }

  &__logo {
    flex: 1;
    margin-left: 12px;
    display: flex;
    align-items: center;

    &__title {
      font-size: 14pt;
      color: #000 !important;
      margin: 0 0 0 10px;
    }

    &__img {
      width: 30px;
      height: auto;
    }
  }

  &__avatar {
    text-align: right;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__userdata {
      font-size: 10pt;

      &:hover {
        cursor: default;
      }

      small {
        display: block;
        margin-top: -5px;
      }
    }

    &__item {
      background-color: @primary-color !important;
      color: black !important;
      font-weight: bold;
      font-size: 10pt !important;
      cursor: pointer;
    }

    &__down {
      font-size: 8pt;
      display: inline-block;
      margin-left: 5px;
    }
  }
}

@hack: true; @import "/Users/teomoreiras/Documents/Workspace/Tiffin/tiffin-foods-admin/src/styles/less/_custom-ant.less";