.register-password {
  background-color: @light-gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    &__title.ant-typography {
      font-size: 22pt;
      color: #000;
      font-weight: 500;
      margin: 0px 0px 0px 10px;
    }

    &__img {
      height: auto;
    }
  }

  &__container {
    width: 500px !important;

    @media all and (max-width: 550px) {
      width: 100% !important;
    }

    &__signin {
      &__container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px;

        @media all and (max-width: 1100px) {
          padding: 30px;
        }

        &__form {
          display: flex;
          flex-direction: column;
          width: 90%;

          .advanced-button {
            margin-top: 6px;
          }
        }
      }
    }
  }
}
