.provider-certificate-form {
  &__digital-certificate {
    &__description {
      color: black;
    }

    &__sent {
      margin-top: 8px;

      &__text {
        color: black;
        font-weight: bold;
      }
    }

    &__upload {
      margin-top: 20px;
      margin-bottom: 12px;

      &__content {
        cursor: pointer;

        &__text {
          margin-left: 12px;
          color: black;
        }

        &__checkboxText {
          color: black;
          font-weight: bold;
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}