.export-modal {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
      font-weight: bold;
      margin-right: 10px;
    }

    &__select {
      width: 250px;
    }
  }
}
