.order-form {
  &__main-container {
    width: 100%;
    height: 100%;

    &__content {
      margin: 24px;
      padding-bottom: 24px;
    }
  }

  &__footer {
    .ant-card-bordered {
      border: 0;
    }

    &__card {
      .ant-card-body {
        padding: 10px;
      }

      &__row {
        display: flex;
        justify-content: space-between;

        &__waiting-cell {
          justify-content: space-between;
          display: flex;
          align-items: center;

          &__info-icon {
            margin-left: 5px;
          }
        }
        &__text {
          &--alert {
            color: red !important;
          }
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
    }
  }

  &__generate-button {
    margin: 0 auto 15px;
  }

  &__installment-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;

    &--not-first {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 10px;
      flex: 1;
    }

    &__installment-infos {
      display: flex;
      flex-direction: column;
      word-break: break-word;
      flex: 1;
      width: 100%;
    }

    &__button {
      margin-bottom: 5px;
      background-color: black;
      color: #ffec00;
      border: none;
      margin-top: 10px;

      &:active {
        background-color: lighten(#000, 20);
        color: #ffec00;
        border: none;
      }

      &:focus {
        background-color: black;
        color: #ffec00;
        border: none;
      }

      &:hover {
        background-color: black;
        color: #ffec00;
        border: none;
      }
    }
  }

  &__invoice-ful {
    &__content {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__invoice-box {
    width: 100%;

    &__header {
      font-weight: 600;
      margin-left: 4px;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span.advanced-button__text {
        color: var(@text-color);
        font-weight: 600;
      }

      &__files-number {
        font-size: 16px;
        color: black;
      }
    }

    &__list-files {
      margin-top: 15px;

      ul.ant-list-item-action {
        margin-left: 8px;
      }

      &__actions {
        cursor: pointer;
        color: black;

        .anticon .anticon-more {
          size: 18px;
        }
      }

      &__items {
        &__viewOrderFile {
          white-space: nowrap;
        }

        &__deleteOrderFile {
          color: black;
          cursor: pointer;
        }

        &__deleteOrderFile:hover,
        &__viewOrderFile:hover {
          opacity: 0.7;
        }

        &__meta {
          display: flex;
          align-items: center;

          &__container {
            max-width: 100%;
            position: relative;

            &__title {
              display: block;
              max-width: 55%;
              margin-bottom: 0px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: @link-color;
              font-weight: 600;
              line-height: 18px;
              border-bottom: 1px solid black;
            }

            &__title::after {
              content: attr(data-filetype);
              position: absolute;
              left: 53%;
              top: 0;
              border-bottom: 1px solid black;
            }
          }

          &__description {
            color: black;

            &__file-type {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  &__installments-form {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;

      &__data-input {
        width: 70%;
      }

      &__actions {
        display: flex;
        align-items: center;
        margin-top: 14px;
      }

      &__installmet-action-button {
        padding: 4px 8px;

        &--invisible {
          opacity: 0;
        }
      }
    }
  }

  &__update-installment-form {
    &__container {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &__warning {
    &__noCard {
      margin-bottom: 10px;
    }

    &__status-warning {
      margin-bottom: 12px;

      &__card-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        color: @warning-color;
      }

      .ant-card-body {
        padding: 16px;
      }
    }
  }

  &__products {
    &__stock-warning {
      display: block;
      color: red;
      margin-bottom: 12px;
    }

    &__searchInput {
      padding-bottom: 15px;
      margin-bottom: 15px;
      width: 100%;

      &__wrap {
        display: flex;
        flex-direction: column;

        &__label {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          padding: 0 0 8px;
        }

        &__tip {
          padding-top: 15px;
        }
      }
    }

    &__modal-body {
      &__content {
        &__description {
          padding-top: 24px;
          padding-bottom: 12px;
          border-bottom: #f0f0f0 1px solid;

          &__container {
            padding: 0 24px;

            &__adjust-button {
              border: none;
              padding: 0;
              font-size: 14px;
              font-weight: bold;
              color: black;
              margin-top: 2px;
              background-color: transparent;

              span {
                text-decoration: underline;
              }

              span:hover {
                color: #858585;
                cursor: pointer;
              }
            }
          }
        }
      }

      @media all and (max-width: 1100px) {
        height: 85vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__content {
          &__description {
            &__container {
              padding: 0 10px;
            }
          }
        }
      }
    }
  }

  &__order-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-top: #f0f0f0 1px solid;

    &__item {
      &__value {
        font-weight: bold;
        color: black;
      }

      p {
        margin: 0;
      }
    }

    @media all and (max-width: 1100px) {
      flex-direction: column;
      padding: 20px 10px 0;
      width: 100%;

      &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &__label {
          display: inline-block;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  &__products-list {
    max-height: 250px;
    overflow-y: auto;

    .ant-list-item-meta-content {
      padding-right: 15px;
    }

    &__item {
      border-bottom: #f0f0f0 1px solid !important;
      padding: 18px 24px 18px 24px;

      &__title {
        font-weight: bold;
      }

      &__product-info {
        &__container {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: black;
          text-align: left;
          white-space: nowrap;

          &__order-value,
          &__minimum,
          &__multiple,
          &__discount,
          &__stock {
            &__value {
              font-weight: bold;
            }

            &__stock-value {
              font-size: 14px;
              font-weight: bold;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    &__action {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &__input {
        width: 70px;
        border-radius: 0;

        .ant-input-number-input {
          text-align: center;
        }

        &--warning {
          color: red;
        }

        .ant-input-number-input {
          font-weight: bold;
        }

        .ant-input-number-handler-wrap {
          display: none;
        }
      }

      &__button {
        display: flex;
        align-items: center;

        &__add,
        &__remove {
          font-size: 16px;
          font-weight: bold;
          background-color: #ffec00;
          border: 1px solid #d9d9d9;
          width: 30px;
        }

        &__remove {
          border-right: none;
          border-radius: 4px 0 0 4px;
        }

        &__add {
          border-left: none;
          border-radius: 0 4px 4px 0;
        }

        &__remove.ant-btn,
        &__add.ant-btn {
          padding: 4px 8px;
        }

        &__remove:hover,
        &__add:hover {
          background-color: #b8ae2c;
        }
      }

      &__full-stock {
        text-align: center;

        &__button {
          border: none;
          padding: 0;
          font-size: 12px;
          font-weight: bold;
          color: black;
          margin-top: 6px;
          background-color: Transparent;

          span {
            text-decoration: underline;
          }

          span:hover {
            color: #858585;
            cursor: pointer;
          }
        }
      }
    }

    @media all and (max-width: 1100px) {
      padding: 0 4px;
      overflow-x: hidden;
      max-height: fit-content;
      flex-grow: 4;

      li.ant-list-item {
        display: block;
      }

      &__item {
        padding: 12px 0;

        &__product-info {
          margin-bottom: 16px;

          &__container {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 'stock multiple' 'discount discount';

            &__multiple {
              grid-area: multiple;
            }

            &__discount {
              grid-area: discount;
            }

            &__stock {
              grid-area: stock;
            }

            &__order-value,
            &__minimum,
            &__multiple,
            &__discount,
            &__stock {
              &__label {
                font-size: 10px;
              }

              &__value {
                font-weight: bold;
                font-size: 10px;
              }

              &__stock-value {
                font-size: 10px;
              }

              p {
                margin: 0;
              }
            }
          }
        }
      }

      &__action {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 80px;
      }
    }
  }
}
