.advanced-data-table {
  &--disable-line-breaks * {
    white-space: nowrap !important;
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  &__popover {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__color-picker {
      margin-bottom: 10px;
    }

    &__input {
      min-width: 150px;
    }

    &__buttons-bar {
      display: flex;
      justify-content: space-evenly;
      width: 100%;

      &__button {
        border: none;
        border-radius: 3px;
        flex: 2;
        margin-top: 10px;

        &--save {
          background-color: #ffec00;
          color: black;
          margin-left: 2px;

          &:hover {
            background-color: yellow;
            cursor: pointer;
          }
        }

        &--cancel {
          background-color: black;
          color: white;
          margin-right: 2px;

          &:hover {
            background-color: #343333;
            cursor: pointer;
          }
        }
      }
    }
  }

  &__editable-cell {
    border: 2px solid transparent;
    padding: 5px 8px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;

    &:not(:disabled):hover {
      cursor: pointer;
      border-color: #ccc;
      border-radius: 5px;
      background-color: white;
    }

    &--no-wrap {
      white-space: pre-wrap !important;
    }
  }

  &__payment-status {
    &--late,
    &--late .ant-table-cell-fix-left,
    &--late .ant-table-cell-row-hover,
    &--late .ant-table-row:hover > td {
      background-color: #fee8ea !important;
    }
  }

  &__warnings {
    background-color: #fffdc1 !important;

    .ant-table-row,
    .ant-table-cell-fix-left,
    .ant-table-cell-row-hover {
      background-color: #fffdc1 !important;
    }
  }
}
