.provider-rating-details-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    @media all and (max-width: 900px) {
      width: 60vw;
    }

    @media all and (min-width: 900px) {
      width: 40vw;
    }

    @media all and (max-width: 700px) {
      width: 80vw;
    }

    display: flex;
    justify-content: center;

    &__avatar-column {
      &__avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        margin-right: 10px;

        &__img {
          font-size: 28px !important;
          border-radius: 50%;
          max-width: 100%;
          max-height: 100%;
          background-color: yellow;
          font-weight: bold;
          color: black;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &__body-infos {
      @media all and (max-width: 700px) {
        width: 100%;
      }

      width: 90%;

      &__header {
        width: 100%;

        &__user {
          display: flex;

          &__infos {
            display: block;
            max-width: 80%;
            white-space: nowrap;

            @media all and (min-width: 1100px) {
              max-width: 90%;
            }

            &__name {
              font-weight: bold;
              font-size: 18px;
              height: 24px;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &__date {
              font-size: 12px;
            }
          }
        }
      }

      &__body {
        width: 100%;
        margin-top: 30px;

        &__evaluation {
          &__title {
            font-weight: bold;
          }

          &__evaluation-container {
            margin-top: 15px;
          }
        }

        &__reply {
          margin-top: 30px;
          &__title {
            font-weight: bold;
          }

          &__reply-box {
            margin-top: 5px;

            &__textarea {
              resize: none;
            }
          }
        }
      }

      &__footer {
        width: 100%;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &__popover {
          &__buttons {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
