.import-modal {
  width: 600px !important;

  &__template {
    padding-bottom: 20px;
  }

  &__input {
    display: unset !important;
  }

  &__import_error {
    margin-top: 10px;
    color: red;
    text-decoration: underline;
  }

  &__error-box {
    title {
      font-size: 2opx;
      text-decoration: underline;
    }
  }
}
