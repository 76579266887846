.collection-table-content {
  &__ant-dropdown-link {
    font-size: 20px;
  }

  &--unavailable {
    color: red;
    font-weight: bold;

    &__icon {
      padding-right: 5px;
    }
  }
}

.collection-content {
  &__search-input .advanced-input {
    width: 240px;
    margin-right: 10px;
    padding: 0;
  }

  &__search-popover .anticon-question-circle {
    font-size: 20px;
    color: #000000;
  }

  &__search-filters {
    margin-top: 15px;

    &__buttons .advanced-button {
      margin-right: 10px;
    }
  }
}