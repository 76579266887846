.advanced-switch {
  padding-bottom: 15px;

  &__label {
    padding-bottom: @label-spacing;

    &__inner {
      display: block;
      font-size: 14px;
      padding: 0 0 8px;
    }
  }
}
