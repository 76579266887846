.advanced-checkbox {
  display: block;
  width: 100%;
  padding-bottom: 15px;

  &--no-padding {
    padding-bottom: 0;
  }

  &--disabled {
    cursor: default !important;
  }

  &__label {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    cursor: pointer !important;

    &--centered {
      justify-content: center;
    }

    &--disabled {
      cursor: default !important;
    }

    &__inner {
      font-size: 14px;
      padding-left: 7px;
    }
  }
}
