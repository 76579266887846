.advanced-input {
  padding-bottom: 15px;

  &__label {

    &__inner {
      display: block;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      padding: 0 0 8px;
    }

    &__error {
      display: block;
      font-size: 14px;
      color: red;
      padding: 5px 0;
    }
  }
}
