.password {
  &__recovery {
    width: 100vw;
    height: 100vh;
    background-color: @light-gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 30px;

      &__img {
        height: auto;
      }
    }

    &__container {
      width: 500px !important;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

      @media all and (max-width: 550px) {
        width: 100% !important;
        box-shadow: none;
        border: none;
      }

      &__signin {

        &__container {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 40px;

          @media all and (max-width: 1100px) {
            padding: 30px;
          }

          &__text {
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: center;
          }

          &__form {
            display: flex;
            flex-direction: column;
            width: 100%;

            &__footer {
              display: grid;
              grid-template-columns: 1fr 1fr;
              justify-content: space-between;
              gap: 10px;
            }
          }
        }
      }
    }
  }
}
