.advanced-date-picker {
  padding-bottom: 15px;
  display: block;
  width: 100%;

  &__label {
    display: block;
    width: 100%;

    .ant-picker {
      display: block !important;
      width: 100% !important;
    }

    &__inner {
      display: block;
      width: 100%;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      padding: 0 0 8px;
    }
  }
}
