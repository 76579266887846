.Administration-tags {
  &__tag-cell {
    border-radius: 50px !important;
  }

  &__inner-color-cell {
    width: 20px;
    height: 20px;
    border: 1px solid black;
    margin-right: 5px;

    &__row {
      display: flex;
      align-items: center;
    }
  }
}
