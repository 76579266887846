.Advanced-preview-tag {
  &__container {
    height: 145px;
    width: 372px;
    margin-bottom: 20px;
    border: 1px solid lightgray;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding-left: 10px;

    &__picture {
      width: 120px;
      height: 120px;
      border: 1px solid lightgray;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      background-size: cover;
      border-radius: 10px;
    }

    &__infos {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      &__top {
        &__title {
          font-weight: bold;
          font-size: 14px;
          font-family: 'Montserrat', sans-serif !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }

        &__description {
          font-size: 12px;
          font-family: 'Montserrat', sans-serif !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
      }

      &__bottom {
        &__min-order {
          font-size: 12px;
          font-family: 'Montserrat', sans-serif !important;
        }

        &__price {
          font-weight: bold;
          font-size: 13px;
          font-family: 'Montserrat', sans-serif !important;
        }
      }
    }

    &__tag {
      border: none;
      border-radius: 50px !important;
      padding: 10px;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      min-width: 90px !important;
    }

    &__text {
      border: none;
      border-radius: 50px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      max-width: 200px !important;
      font-size: 11px;
      text-align: center;
      min-width: 80px !important;
      font-weight: bold;

      &:focus-visible {
        outline: none !important;
      }
    }
  }
}
