html,
body {
  font-family: 'Montserrat', sans-serif !important;
  overflow: hidden;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.spacing-bottom-sm {
  padding-bottom: 15px;
}

.remove-padding {
  padding: 0 !important;
}

.ant-steps-item-title {
  font-size: 12px !important;
  line-height: 12px !important;
}

.box-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media all and (max-width: 1100px) {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .box-container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .box-container {
    width: 900px;
  }
}

.box-container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.box-container:before,
.box-container:after,
.box-container-fluid:before,
.box-container-fluid:after,
.row:before,
.row:after {
  display: table;
  content: " ";
}

.clearfix:after,
.box-container:after,
.box-container-fluid:after,
.row:after {
  clear: both;
}
