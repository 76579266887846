@primary-color: #ffec00; // primary color for all components
@label-spacing: 5px;
@light-gray: rgba(254, 255, 254, 0.85);

@link-color: black; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color

@border-radius-base: 4px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers

@font-size-base: 14px; // major text font size
@font-family: 'Montserrat', sans-serif;

@menu-dark-bg: black;
@menu-dark-selected-item-icon-color: black;
@menu-dark-selected-item-text-color: black;

.ant-pagination-item-active {
  border-color: black;
  border-width: 1.5px;
}
.ant-pagination-item-active a {
  color: black;
  font-weight: bold;
}

// button
.ant-btn-primary {
  color: black !important;
}

// checkbox
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black;
  border-color: black;
}
.ant-checkbox-checked::after {
  border: 1px solid black;
}
.ant-dropdown-menu-item-selected {
  color: black;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #858585;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #fff;
}

// table
.ant-table-filter-trigger.active {
  color: black;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: black;
}
.ant-table-pagination.ant-pagination {
  margin: 25px 0 0;
}

// card
.ant-card {
  width: 100%;
}

// loading
.ant-loading-icon {
  color: black;
}

// input
.ant-input:hover {
  border-color: rgba(0, 0, 0, 0.2);
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}
.ant-input:active {
  border-color: rgba(0, 0, 0, 0.2);
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}
.ant-input:focus {
  border-color: rgba(0, 0, 0, 0.5);
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}
.ant-input-password:hover {
  border-color: rgba(0, 0, 0, 0.2);
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}
.ant-input-password:active {
  border-color: rgba(0, 0, 0, 0.2);
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}
.ant-input-password:focus {
  border-color: rgba(0, 0, 0, 0.7);
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

::-moz-selection {
  background-color: highlight;
  color: highlighttext;
}
::selection {
  background-color: highlight;
  color: highlighttext;
}

// drawer
.ant-drawer-header {
  display: flex;
  flex-direction: row;
  position: unset;
  align-items: center;
  padding: 15px;
}
.ant-drawer-close {
  position: unset;
  font-size: unset;
  padding: 0;
}
.ant-drawer-title {
  flex: 1;
  font-size: 18px;
}
.drawer .ant-drawer-content-wrapper {
  width: 25% !important;

  @media all and (max-width: 1500px) {
    width: 35% !important;
  }

  @media all and (max-width: 1200px) {
    width: 40% !important;
  }

  @media all and (max-width: 1000px) {
    width: 55% !important;
  }

  @media all and (max-width: 700px) {
    width: 100% !important;
  }
}

.ant-menu-inline .ant-menu-item {
  padding-left: 15px !important;

  a {
    font-size: 10pt !important;
  }
}

.ant-menu-dark .ant-menu-item-selected {
  a {
    color: #000 !important;
  }
}

.ant-layout-sider-trigger {
  background: black;
}

.ant-table-thead {
  tr {
    th {
      font-size: 10px;
      white-space: nowrap;
    }
  }
}
