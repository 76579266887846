.downloadScreen {
  &__container {
    width: 100vw;
    height: 92.3vh;
    background-color: @light-gray;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;

    &__card {
      width: 700px !important;
      height: 65.3vh;
      z-index: 10;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);

      &__content {
        height: 92.3vh;
        display: flex;

        &__text {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          h1 {
            font-weight: bold;
            text-align: center;
          }

          h2 {
            text-align: center;
            width: 70%;
            font-size: 13pt;
          }
        }

        &__logo {
          display: flex;
          flex: 1;
          justify-content: space-evenly;
          max-width: 480px;

          &__container {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 20px;
          }

          &__imgLogo {
            width: 30%;
          }

          &__img {
            width: 100%;
            box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
              0 9px 28px 8px rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
  }
}
