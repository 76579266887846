.highlights-modal {
  @media all and (max-width: 1100px) {
    width: 100%;
  }

  &__footer {
    text-align: right;
    padding: 0 7px;
  }

  &__form {
    position: relative;

    &__loading {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      z-index: 1500;
    }

    &__dropzone {
      padding-bottom: 15px;
      margin: -15px;

      &__bottom {
        padding-top: 5px;
      }
    }

    &__input {
      display: block;
      width: 100%;
    }
  }
}
