.advanced-modal {
  padding-bottom: 15px;

  @media all and (max-width: 1100px) {
    top: 0 !important;
    margin: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    max-width: 100vw !important;
  }

  .ant-modal-content {

    @media all and (max-width: 1100px) {
      border-radius: 0 !important;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    }
  }

  .ant-modal-header {

    @media all and (max-width: 1100px) {
      padding: 10px !important;
    }
  }

  .ant-modal-close-x {

    @media all and (max-width: 1100px) {
      height: 30px !important;
      width: 35px !important;
      line-height: 43px;
    }
  }

  .ant-modal-body {

    @media all and (max-width: 1100px) {
      flex-grow: 1 !important;
    }
  }

  &__label {
    padding: 15px 0 0 15px;

    &__inner {
      font-size: 16px;
      font-weight: 600;
      color: @heading-color;
    }
  }
}
