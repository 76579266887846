.highlights-modal__advanced-preview {
  height: 230px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid lightgray;
  display: flex;
  align-items: flex-end;
  border-radius: 10px;
  background: #f0f2f5;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  &__back {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), transparent);
    height: 100px;
    flex: 1;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: calc(calc(7.5px * 2) * 3) calc(7.5px * 2) calc(7.5px * 1);
  }

  &__infos {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    word-break: break-all;
    line-height: 1.3;

    &__title {
      font-weight: 700;
      font-size: 20px;
      margin: 0;
    }

    &__subtitle {
      font-size: 15px;
      margin: 0;
    }
  }

  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
}
