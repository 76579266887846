.advanced-text-area {
  padding-bottom: 15px;

  &__label {

    &__inner {
      display: block;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      padding: 0 0 8px;
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 5px;

    &__of {
      padding: 0 5px;
    }
  }
}

@hack: true; @import "/Users/teomoreiras/Documents/Workspace/Tiffin/tiffin-foods-admin/src/styles/less/_custom-ant.less";