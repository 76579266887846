.footer {
  padding: 20px 5px 20px 5px !important;

  &__container {
    height: 3vh;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__img {
    width: 43px;
    height: 40px;
  }

  p {
    margin: 0 0 0 5px;
    font-size: 9pt;

    @media all and (max-width: 700px) {
      font-size: 5.5pt;
    }
  }
}
