.certificate-warning-form {
  &__description {
    font-weight: bold;
    font-size: 12px;
    color: black;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}