.login {
  width: 100vw;
  height: 100vh;
  background-color: @light-gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    justify-content: center;

    &__img {
      width: 130px;
      height: auto;
    }
  }

  &__container {
    width: 500px !important;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    @media all and (max-width: 550px) {
      width: 100% !important;
      box-shadow: none;
      border: none;
    }

    &__signin {

      &__container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px;

        @media all and (max-width: 1100px) {
          padding: 30px;
        }

        &__form {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: flex-end;

          &__button,
          .advanced-button {
            margin-top: 15px !important;
          }
        }
      }
    }

    &__signup {

      &__container {
        background-color: #f4f4f4;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;

        @media all and (max-width: 800px) {
          flex-direction: column;
          background-color: #fff;
        }

        &__title {
          font-size: 10pt;
          margin: 0 !important;
        }

        &__button,
        .advanced-button__text {
          color: black !important;
          font-weight: bold;
        }

        &__button,
        .advanced-button {
          font-size: 10pt;
          padding: 0;
          margin-left: 2px;
        }
      }
    }

    &__forget__password__container {
      background-color: #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding-bottom: 20px;

      @media all and (max-width: 800px) {
        flex-direction: column;
        background-color: #fff;
      }

      &__title {
        font-size: 10pt;
        margin: 0 !important;
      }

      &__button,
      .advanced-button {
        font-size: 10pt;
        font-weight: bold;
        padding: 0;
        margin-left: 2px;
      }
    }
  }
}
