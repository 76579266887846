.advanced-input-number {
  padding-bottom: 15px;
  width: 100%;
  display: block;

  &__label {
    padding-bottom: @label-spacing;
    width: 100%;
    display: block;

    &__inner {
      width: 100%;
      display: block;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      padding: 0 0 8px;

      &__input {
        width: 100%;
      }
    }
  }
}
