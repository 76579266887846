.forbidden {

  &__container {
    width: 100vw;
    height: 92.3vh;
    background-color: @light-gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__content {
      height: 92.3vh;

      &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1 {
          font-weight: bold;
          text-align: center;
        }

        h2 {
          text-align: center;
          width: 70%;
          font-size: 13pt;
        }
      }

      &__logo {
        display: flex;
        align-items: center;
        justify-content: center;

        &__img {
          width: 70%;
          height: 100%;
        }
      }
    }
  }
}
