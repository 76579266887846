.advanced-select {
  padding-bottom: 15px;

  &__label {
    display: block;
    width: 100%;
    padding-bottom: @label-spacing;
  }

  &__select {
    display: block;
    width: 100%;
  }
}
