.user-password-modal {
  color: black;

  &__header {
    font-size: 20px;
    font-weight: bold;
    padding: 10px 15px;
    border-bottom: 1px solid #efefef;
  }

  &__content {
    padding: 15px;

    &__info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media all and (max-width: 1100px) {
        padding-right: 0;
      }

      &__description {
        font-size: 14px;
        padding: 0 15px 15px 0;

        &__button {

          &__icon {
            position: relative;
            top: 2px;
            right: -3px;
          }
        }
      }
    }
  }

  &__footer {
    border-top: 1px solid #efefef;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &__icon {
      position: relative;
      top: 2px;
      right: -3px;
    }
  }
}
