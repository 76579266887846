.collection-form {
  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__products {
    &__searchInput {
      padding-bottom: 15px;
      margin-bottom: 15px;
      width: 100%;

      &__wrap {
        display: flex;
        flex-direction: column;

        &__label {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          padding: 0 0 8px;
        }

        &__tip {
          padding-top: 15px;
        }
      }
    }
  }
}