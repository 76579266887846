.orders-panel {
  .ant-table-thead {
    tr {
      th {
        font-size: 12px;
      }
    }
  }

  &__userName {
    display: flex;
    align-items: center;
    text-align: center;
  }

  &__warnings {
    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 16px;
      font-weight: bold;
      font-size: 16px;

      &__icon {
        margin-right: 6px;
      }
    }
  }
}
