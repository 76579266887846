.password-strenght-checker {
  padding-top: 15px;

  &__levels {
    display: flex;

    &__level {
      width: 70px;
      height: 5px;
      background-color: #E9E9E9;
      margin-right: 5px;

      &--active {
        background-color: #4ECB71;
      }
    }
  }

  &__message {
    margin-top: 3px;
  }

  &__instructions {
    margin-top: 7.5px;

    &__title {
      font-size: 14px;
      font-weight: bold;
    }

    &__line {
      font-size: 13px;
      margin-top: 7.5px;

      &__list {
        padding-left: 15px;

        &__item {
          color: #E30000;
          font-weight: bold;

          &--checked {
            text-decoration: line-through;
            color: #000;
            font-weight: normal;
          }
        }
      }
    }
  }
}
