.customer-import-modal {

  &__template {
    padding-top: 10px;

    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  &__select {
    width: 100%;
    margin-bottom: 10px !important;
    padding-top: 10px;
  }
}
