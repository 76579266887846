.product-form {
  position: relative;

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(255,255,255,0.7);
  }

  &__inner {

    &__loading {

    }
  }
}
