.Tags-form {
  &__row {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    border-radius: 5px;
  }

  &__preview {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
