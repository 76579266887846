.panel {
  &__sider {
    background-color: #000000 !important;
    height: 100vh !important;
    min-height: 100vh !important;
  }

  &__layout {
    &__wrapper {
      overflow: scroll;
      max-height: 100vh;
      padding-top: 44px;
    }

    &__content {
      margin: 25px;
      padding: 25px;
      background: #fff;
      flex: none !important;
      border-radius: 10px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.15);
      position: relative;

      @media all and (max-width: 1100px) {
        padding: 0 0 120px;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
      }

      &__link {
        color: black;
        cursor: pointer;
      }

      &__title {
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 30px;

        @media all and (max-width: 1100px) {
          font-size: 16px;
          padding: 15px;
        }

        &__icon {
          color: #000;
          margin-right: 15px;
        }
      }

      &--breadcrumb {
        padding: 15px;
      }

      &--advanced-filter {
        padding: 0;
        margin: 0 25px 25px 25px;
        border-bottom: none;
        border: 0;
        border-radius: 8px;

        &__item {
          margin-bottom: 10px;
        }

        &__label {
          margin: 10px 0 5px;
        }

        &__checks {
          display: flex;
          flex-direction: row;

          &__item {
            padding-right: 15px;
          }
        }

        &__button {
          margin-left: 15px
        }

        @media all and (max-width: 1100px) {
          margin: 15px 0 !important;
          border-radius: 0 !important;
        }

        .ant-collapse-item {
          border-bottom: 0 !important;
        }

        .ant-collapse-content {
          border-radius: 0 0 8px 8px !important;
        }
      }

      &--content-data {
        margin-top: 0;
      }

      &__actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      &__column-item {
        display: block;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.p-0 {
  padding: 0 !important;
}
